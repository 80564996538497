import './util/configure_csp_nonce.js'

import './vendor/css/jquery-ui.css'
import 'mediaelement/build/mediaelementplayer.css'

import $ from 'jquery'

require("./vendor/jquery-ui.js");
require("./vendor/jquery.scrollTo.js");

require("./app/app.js");
require("./app/views/dialog_view.js");
require("./app/views/base_behavior.js");

require("./app/widgets/twistle_widgets.js");
require("./app/widgets/attachments.js");
require("./app/widgets/removeable.js");

require("./app/models/conversation.js");
require("./app/collections/account_list.js");
require("./app/collections/contact_list.js");

require("./app/views/login_desktop_view.js");
require("./app/views/login_form_view.js");
require("./app/views/login_account_setup_view.js");
require("./app/views/photo_upload_view.js");
require("./app/views/login_forgot_password_view.js");
require("./app/views/invite_code_signup_view.js");
require("./app/views/login_password_reset_view.js");
require("./app/views/mobile_app_install_view.js");

import loadWebApp from "./app/login_setup.js"
import { loadTemplatesForApp } from "util/template_renderer"

$(() => {
    loadTemplatesForApp(document, App);
    const accountData = window.__TWISTLE_CONFIG__.accountData;
    const appConfigData = window.__TWISTLE_CONFIG__.appConfigData;
    loadWebApp(accountData, appConfigData, false);
});