import Backbone from 'backbone'

const InviteRouter = Backbone.Router.extend({
    routes: {
        "setup": "setup",
        "setup/continue": "setupContinue",
        "forgot_password/:mobileAppMode": "forgot_password",
        "forgot_password": "forgot_password",
        "reset": "reset",
        "invitecode": "inviteCodeSignup",
        '*path': 'inviteCodeSignup'
    },

    setup: function() {
        App.trigger("app:start_account_setup", {
            fromRouter: true
        });
    },

    inviteCodeSignup: function(){
        App.trigger("app:start_invite_code_signup", {
            fromRouter: true
        });
    },

    setupContinue: function() {
        App.trigger("app:continue_account_setup", {
            fromRouter: true
        });
    },

    forgot_password: function(mobileAppMode) {
        App.trigger("app:forgot_password", {
            fromRouter: true,
            mobileAppMode: mobileAppMode
        });
    },

    reset: function() {
        App.trigger("app:password_reset", {
            fromRouter: true
        });
    },

    defaultRoute: function(path) {
        App.trigger("app:reset_login_desktop", {
            fromRouter: true
        });
    }
});

export default InviteRouter;